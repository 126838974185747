import {IPlatformAPI, IPlatformServices, IAppData} from '@wix/native-components-infra/dist/es/src/types/types';
import {createOOIStoreFrontBILogger} from '@wix/wixstores-client-core/dist/src/bi/configure-ooi-front-bi-logger';
import {EditorMode} from '@wix/settings-editor-common-components/dist/src/constants/enums';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types';
import {QueryParamsService} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/QueryParamsService/QueryParamsService';

export class CurrencyConverterSiteStore extends SiteStore {
  public siteMainCurrency: string;
  public currentCurrencyCode: string;
  // @ts-expect-error
  public baseUrls: {currencyConverterBaseUrl: string};
  private readonly queryParamsService: QueryParamsService;

  constructor(
    {instance, instanceId, baseUrls, appDefinitionId}: IAppData,
    {pubSub, storage}: IPlatformAPI,
    {window: wixWindow, site, location, seo, user: usersApi}: IWixAPI | any,
    platform: IPlatformServices
  ) {
    super(
      {instance, instanceId, baseUrls, appDefinitionId} as IAppData,
      {pubSub, storage} as IPlatformAPI,
      {window: wixWindow, site, location, seo, user: usersApi},
      platform
    );
    const user = {visitor_id: platform.bi.visitorId};
    /* istanbul ignore next: need test - EditorMode.Desktop */
    this.biLogger = createOOIStoreFrontBILogger(
      user,
      this.platformServices.bi.biToken,
      {
        editorMode: this.isDesktop() ? EditorMode.Desktop : EditorMode.Mobile,
        isMerchant: false,
        appName: 'currency-site-settings',
      },
      this.platformServices.biLoggerFactory()
    );

    this.queryParamsService = new QueryParamsService(this as any);

    this.siteMainCurrency = this.siteApis.currency;
    this.currentCurrencyCode = this.getCurrentCurrencyField() || this.siteMainCurrency;

    /* istanbul ignore next: need test - this.windowApis.multilingual.currentLanguage flow */
    this.locale =
      this.windowApis.multilingual && this.windowApis.multilingual.isEnabled
        ? this.windowApis.multilingual.currentLanguage
        : this.siteApis.language;
  }

  public getCurrentCurrencyField(): string {
    return this.queryParamsService.getQueryParam('currency');
  }

  public updateQueryParams(queryParams: {}): void {
    this.location.queryParams.add({...queryParams});
  }

  public removeQueryParams(params: string[]): void {
    this.location.queryParams.add(params.reduce((acc, param) => ({...acc, [param]: ''}), {}));
  }
}
