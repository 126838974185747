/* eslint-disable import/no-cycle */
import {
  IWidgetControllerConfig,
  IViewerScript,
  IAppData,
  IPlatformAPI,
  IWixAPI,
  IPlatformServices,
} from '@wix/native-components-infra/dist/src/types/types';
import {
  withErrorReporting,
  errorReporter,
  setSentryInstance,
} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/errorReporter';
import {createAppController} from './appController';
import {CurrencyConverterSiteStore} from './currencyConverterSiteStore';

export interface IContext {
  siteStore?: CurrencyConverterSiteStore;
}
export type ICurrencyConverterWidgetControllerConfig = IWidgetControllerConfig & {context?: IContext};

const context: IContext = {};
const createErrorReportingWrapper = (config: ICurrencyConverterWidgetControllerConfig) => {
  const sentryInstance: any = config.platformAPIs.monitoring.createMonitor(
    'https://f01af0a8fa004372bf668602ac3d1409@sentry.io/3046044'
  );
  sentryInstance.setUserContext({
    id: config.appParams.instanceId,
    url: config.wixCodeApi.location.baseUrl,
    uuid: config.platformAPIs.bi.visitorId,
  });
  setSentryInstance(sentryInstance);
  const setPropsWithErrorsReporting = (p) => config.setProps(withErrorReporting(errorReporter)(p));
  return withErrorReporting(errorReporter)(
    createAppController(
      {
        ...config,
        setProps: setPropsWithErrorsReporting,
      },
      context,
      errorReporter
    )
  );
};

const createControllers = (appControllerConfig: ICurrencyConverterWidgetControllerConfig[]) => {
  return appControllerConfig.map((config) => createErrorReportingWrapper(config));
};

export const createViewerScript: IViewerScript = {
  initAppForPage: (
    initParams: IAppData,
    apis: IPlatformAPI,
    namespaces: IWixAPI,
    platformServices: IPlatformServices
  ) => {
    context.siteStore = new CurrencyConverterSiteStore(initParams, apis, namespaces, platformServices);
    return Promise.resolve();
  },
  // @ts-expect-error
  createControllers: (appControllerConfig: IWidgetControllerConfig[]) => {
    return createControllers(appControllerConfig);
  },
};
